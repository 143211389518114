exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-detail-tsx": () => import("./../../../src/pages/blog-detail.tsx" /* webpackChunkName: "component---src-pages-blog-detail-tsx" */),
  "component---src-pages-causes-tsx": () => import("./../../../src/pages/causes.tsx" /* webpackChunkName: "component---src-pages-causes-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-join-us-intern-tsx": () => import("./../../../src/pages/join-us/intern.tsx" /* webpackChunkName: "component---src-pages-join-us-intern-tsx" */),
  "component---src-pages-join-us-tsx": () => import("./../../../src/pages/join-us.tsx" /* webpackChunkName: "component---src-pages-join-us-tsx" */),
  "component---src-pages-join-us-volunteer-tsx": () => import("./../../../src/pages/join-us/volunteer.tsx" /* webpackChunkName: "component---src-pages-join-us-volunteer-tsx" */),
  "component---src-pages-my-account-tsx": () => import("./../../../src/pages/my-account.tsx" /* webpackChunkName: "component---src-pages-my-account-tsx" */),
  "component---src-pages-portofolio-tsx": () => import("./../../../src/pages/portofolio.tsx" /* webpackChunkName: "component---src-pages-portofolio-tsx" */),
  "component---src-pages-program-tsx": () => import("./../../../src/pages/program.tsx" /* webpackChunkName: "component---src-pages-program-tsx" */),
  "component---src-pages-publication-news-tsx": () => import("./../../../src/pages/publication/news.tsx" /* webpackChunkName: "component---src-pages-publication-news-tsx" */),
  "component---src-pages-publication-tsx": () => import("./../../../src/pages/publication.tsx" /* webpackChunkName: "component---src-pages-publication-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-shop-cart-tsx": () => import("./../../../src/pages/shop/cart.tsx" /* webpackChunkName: "component---src-pages-shop-cart-tsx" */),
  "component---src-pages-shop-checkout-tsx": () => import("./../../../src/pages/shop/checkout.tsx" /* webpackChunkName: "component---src-pages-shop-checkout-tsx" */),
  "component---src-pages-shop-full-width-tsx": () => import("./../../../src/pages/shop/full-width.tsx" /* webpackChunkName: "component---src-pages-shop-full-width-tsx" */),
  "component---src-pages-shop-left-sidebar-tsx": () => import("./../../../src/pages/shop/left-sidebar.tsx" /* webpackChunkName: "component---src-pages-shop-left-sidebar-tsx" */),
  "component---src-pages-shop-list-tsx": () => import("./../../../src/pages/shop/list.tsx" /* webpackChunkName: "component---src-pages-shop-list-tsx" */),
  "component---src-pages-shop-right-sidebar-tsx": () => import("./../../../src/pages/shop/right-sidebar.tsx" /* webpackChunkName: "component---src-pages-shop-right-sidebar-tsx" */),
  "component---src-pages-shop-wishlist-tsx": () => import("./../../../src/pages/shop/wishlist.tsx" /* webpackChunkName: "component---src-pages-shop-wishlist-tsx" */),
  "component---src-pages-single-products-tsx": () => import("./../../../src/pages/single-products.tsx" /* webpackChunkName: "component---src-pages-single-products-tsx" */),
  "component---src-pages-team-member-tsx": () => import("./../../../src/pages/team-member.tsx" /* webpackChunkName: "component---src-pages-team-member-tsx" */)
}

